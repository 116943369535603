<template>
  <div class="con">
    <div class="estateBox" @click="changeEstate">
      <van-icon name="location" color="#1989fa" />
      <span class="name">{{ estateName?estateName:'暂无房产' }}</span>
      <div class="arrow"></div>
    </div>
    <van-popup v-model="showEstate" position="bottom">
      <van-picker
        show-toolbar
        value-key="estateName"
        :default-index="currentEstateIndex"
        :columns="estateList"
        @cancel="showEstate = false"
        @confirm="onConfirmEstate"
      />
    </van-popup>

    <div class="banner">
      <van-swipe class="home-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item class="swipe-item">
          <img :src="banner1" alt="">
        </van-swipe-item>
        <van-swipe-item class="swipe-item">
          <img :src="banner2" alt="">
        </van-swipe-item>
        <van-swipe-item class="swipe-item">
          <img :src="banner4" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>

    <van-row gutter="20" class="links-list">
      
      <!-- 生活服务 -->
      <van-col span="24">
        <h4 class="links-title">便民服务</h4>
        <van-row type="flex" class="links">
          <van-col span="6" class="link-item">
            <div class="div">
              <img src="@/assets/services/chongdian.png" alt="" />
              <span>智能充电</span>
              <wx-open-launch-weapp
                id="launch-btn"
                style="position: absolute; top: 0; width: 100%; height: 100%"
                :username="'gh_d3053e832a09'"
                path="pages/PowerStation/index"
              >
                <script type="text/wxtag-template">
                  <style>
                    .box_a {
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                    }
                  </style>
                  <div class="box_a"></div>
                </script>
              </wx-open-launch-weapp>
            </div>
          </van-col>

          <van-col span="6" class="link-item">
            <div class="div">
              <img src="@/assets/services/shenghuo.png" alt="" />
              <span class="title">生活缴费</span>
              <wx-open-launch-weapp
                id="launch-btn"
                style="position: absolute; top: 0; width: 100%; height: 100%"
                :username="'gh_aceb9bd462ab'"
                path="main/pages/nativeindex/nativeindex"
              >
                <script type="text/wxtag-template">
                  <style>
                    .box_a {
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                    }
                  </style>
                  <div class="box_a"></div>
                </script>
              </wx-open-launch-weapp>
            </div>
          </van-col>

          <van-col span="6" class="link-item">
            <a class="div" href="http://wx.weather.com.cn/mweather/101120901.shtml#1"> 
            <!-- <a class="div" href="http://www.weather.com.cn/forecast/index_mapFull.shtml"> -->
              <img src="@/assets/services/tianqi.png" alt="" />
              <span>天气信息</span>
            </a>
          </van-col>

          <van-col span="6" class="link-item">
            <a class="div" href="https://m.amap.com/"> 
              <img src="@/assets/services/fujin.png" alt="" />
              <span>附近网点</span>
            </a>
          </van-col>

          <van-col span="6" class="link-item">
            <a class="div" href="https://yjt.sddingkao.com/web/module/common/hospital/">
              <img src="@/assets/services/yiyuan.png" alt="" />
              <span>寻找医院</span>
            </a>
          </van-col>

          <van-col span="6" class="link-item">
            <div class="div">
              <img src="@/assets/services/bianmin.png" alt="" />
              <span class="title">便民网购</span>
              <wx-open-launch-weapp
                id="launch-btn"
                style="position: absolute; top: 0; width: 100%; height: 100%"
                :username="'gh_e3e0d867dd82'"
                path="views/index/service-center/service-center"
              >
                <script type="text/wxtag-template">
                  <style>
                    .box_a {
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                    }
                  </style>
                  <div class="box_a"></div>
                </script>
              </wx-open-launch-weapp>
            </div>
          </van-col>

          <van-col span="6" class="link-item">
            <div class="div">
              <img src="@/assets/services/jiazheng.png" alt="" />
              <span class="title">家政服务</span>
              <wx-open-launch-weapp
                id="launch-btn"
                style="position: absolute; top: 0; width: 100%; height: 100%"
                :username="'gh_b0d39e167892'"
                path="pages/index/index"
              >
                <script type="text/wxtag-template">
                  <style>
                    .box_a {
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                    }
                  </style>
                  <div class="box_a"></div>
                </script>
              </wx-open-launch-weapp>
            </div>
          </van-col>

          <van-col span="6" class="link-item">
            <a class="div" @click="goPage('/community')">
              <img src="@/assets/services/more.png" alt="" />
              <span>更多服务</span>
            </a>
          </van-col>
          
        </van-row>
      </van-col>

      <van-col span="24">
        <h4 class="links-title ">智慧社区</h4>
        <van-row type="flex" class="links">
          <van-col span="12" class="link-item">
            <a class="div-2" style="background-color: #fff5ea;" @click="goPage('/socialSharing')">
              <span>社情反映</span>
              <img src="@/assets/icon/fenxiang.png" alt=""/>
            </a>
          </van-col>
          <van-col span="12" class="link-item">
            <a class="div-2" style="background-color: #ffefe9;" @click="goPage('/warning')">
              <span>警情上报</span>
              <img src="@/assets/icon/shangbao.png" alt=""/>
            </a>
          </van-col>
          <van-col span="12" class="link-item">
            <a class="div-2" style="background-color: #f1f2fe;" @click="goPage('/propertyRepair')">
              <span>物业报修</span>
              <img src="@/assets/icon/baoxiu.png" alt="" />
            </a>
          </van-col>
          <van-col span="12" class="link-item">
            <div class="div-2" style="background-color: #eef6ff;" @click="goPage('/voting')">
              <span>居民投票</span>
              <img src="@/assets/icon/toupiao.png" alt="" />
            </div>
          </van-col>
          <van-col span="12" class="link-item">
            <a class="div-2" style="background-color: #e7f7f6;" @click="goPage('/discussion')">
              <span>议事协商</span>
              <img src="@/assets/icon/xieshang.png" alt=""/>
            </a>
          </van-col>
          <van-col span="12" class="link-item">
            <a class="div-2" style="background-color: #edfaff;" @click="goPage('/opinion')">
              <span>意见反馈</span>
              <img src="@/assets/icon/yijian.png" alt="" />
            </a>
          </van-col>
        </van-row>
      </van-col>

      <van-col span="24">
        <div class="more-title">
          社区信息
          <div class="more" @click="goMore">
            查看更多
            <img src="@/assets/icon/right.png" alt="">
          </div>
        </div>
        <van-empty v-if="articleList.length == 0" description="暂无信息" />
        <div class="articles" v-else>
          <div class="item" v-for="(item, index) in articleList" :key="index" @click="goDetail(item.id)">
            <van-image :src="(item.cover || item.friendUrl)" fit="cover" />
            <div class="right">
              <div class="title van-ellipsis">{{ item.title }}</div>
              <div class="desc">
                <van-tag size="medium" color="#d9edfd" text-color="#0584EF">{{ item.typeName }}</van-tag>
                <span>{{ item.createTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-col> 
    </van-row>
    


    <!-- tabbar -->
    <van-tabbar v-model="active" @change="onChange" z-index="10" active-color="#2f54eb" placeholder>
      <van-tabbar-item name="/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.home_act : icon.home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/community">
        <span>服务</span>
        <template #icon="props">
          <img :src="props.active ? icon.serve_act : icon.serve" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  name="/my">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.my_act : icon.my" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { removeToken, removeUsernfo } from '@/utils/token.js'
import { getOpenid, getWxConfigs } from '@/api/wxApi'
import { getNoticeList } from '@/api/eventApi'
import store from '@/store'
import wx from 'weixin-jsapi'
import { myProperty, myEstateList, changeEstate } from '@/api/houseApi.js'
import {
  getToken
} from '@/utils/token.js'
export default {
  name: 'Home',
  components: {},
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: 100,
    },
  },
  data() {
    return {
      // classify: classify,
      banner1: require('@/assets/img/banner1.png'),
      banner2: require('@/assets/img/banner2.png'),
      banner3: require('@/assets/img/banner3.png'),
      banner4: require('@/assets/img/banner4.png'),
      active: "/home",
      icon: {
        home_act: require('@/assets/tabbar/home_act.png'),
        home: require('@/assets/tabbar/home.png'),
        serve_act: require('@/assets/tabbar/serve_act.png'),
        serve: require('@/assets/tabbar/serve.png'),
        my_act: require('@/assets/tabbar/my_act.png'),
        my: require('@/assets/tabbar/my.png'),
      },
      wxHtml: '',
      hasRoom: false,
      estateList: [],
      showEstate: false,
      userInfo: this.$store.getters.userInfo,
      articleList: [],
      currentEstateIndex: ''
    }
  },
  
  computed: {
    estateName() {
      return this.$store.getters.currentEstate?this.$store.getters.currentEstate.deptName:''
    }
  },  

  // 创建完成（可以访问当前this实例）
  created() {
  },

  // 挂载完成（可以访问DOM元素）
  mounted() {
    if(this.$store.state.user.openId && getToken()) {
      this.getAlricleList()
      this.getEstateList()
      this.getCurrentEstate()
    }
    

    let url = window.location.href.split('#')[0]
    // // url = url.split('?')[0]
    getWxConfigs({ url: url }).then(res => {
      wx.config({
        debug: false,
        appId: res.data.appId,
        timestamp: '' + res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: ['chooseImage', 'previewImage'],
        openTagList: ['wx-open-launch-weapp'],
      })
      
    
    })

    document.addEventListener('WeixinOpenTagsError', function (e) {
      console.log('无法使用开放标签的错误原因');
      console.log(e.detail.errMsg) // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开发标签，JS-SDK其他功能不受影响
    })
  },

  // 更新之前
  beforeUpdate() {},

  // 更新之后
  updated() {},

  // 销毁之前
  beforeDestroy() {},

  // 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // 方法集合
  methods: {
    onConfirmEstate(row) {
      if(!row) {
        return
      }
      changeEstate({
        deptId: row.estateId
      }).then(res => {
        if (res.code === 200) {
          this.$store.commit('SET_CURRENT_ESTATE', res.data) 
          this.showEstate = false
          this.$store.commit('SET_IS_LOADING', true)
          removeToken()
          store.dispatch('GetToken').then(() => {
            this.$store.commit('SET_IS_LOADING', false)
            location.reload()
            this.$toast({
              type: 'success',
              message: '切换成功',
              duration: 1000
            })
          })
          
          // removeUsernfo()
        }
      })
    },
    // 获取当前所在小区
    getCurrentEstate() {
      changeEstate().then(res => {
        if (res.code === 200) {
          this.$store.commit('SET_CURRENT_ESTATE', res.data) 
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        path: '/preventionDetail?id=' + id,
      })
    },
    goMore() {
      this.$router.push({
        path: '/messageList'
      })
    },
    getAlricleList() {
      getNoticeList({
        current: 1,
        status: 1,
        size: 5,
        isShare: 1, // 不返回社情共享
      }).then(res => {
        if (res.code === 200) {
          this.articleList = res.data.records
        }
      })
    },
    onChange (index) {
      this.$router.replace(index)
    },
    getEstateList() {
      myEstateList().then(res => {
        if(res.code == 200) {
          this.estateList = res.data
          for(let i=0;i<this.estateList.length;i++) {
            if(this.$store.getters.currentEstate.deptName == this.estateList[i].estateName) {
              this.currentEstateIndex = i
            }
          }
        }
      })
    },
    changeEstate() {
      if(!this.$store.getters.currentEstate) {
        return this.$toast({
          type: 'fail',
          message: '请先绑定房产！',
          duration: 1000,
          onClose: () => {
            this.$router.push({
              path: '/houseRegister',
            })
          }
        })
      }
      this.showEstate = true
      // this.$router.push({
      //   path: '/estateList'
      // })
    },
    
    logout() {
      removeToken()
      removeUsernfo()
      
      this.$store.commit('SET_IS_LOADING', true)
      setTimeout(() => {
        this.$store.commit('SET_IS_LOADING', false)
        location.reload()
      }, 200)
    },
    hasCode() {
      let code = this.GetQueryString('code')
      if (code) {
        // console.log('有code')
        // 获取openid
        getOpenid({
          code: code,
        }).then(res => {
          // console.log(res.data);
          store.commit('SET_OPEN_ID', res.data)
        })
      } else {
        // console.log('没有code')
        // window.location.href = url2
        this.getCodeApi()
      }
    },
    getCodeApi() {
      //获取code
      let urlNow = encodeURIComponent(window.location.href)
      // let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知

      let url =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        this.appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=snsapi_base#wechat_redirect'
      window.location.href = url
    },
    GetQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },

    goPage(val) {
      window.location.href = val
      // this.$router.push({
      //   path: val
      // })
    },
    goRepair(val) {
      myProperty().then(res => {
        if(res.code == 200) {
          let list = res.data
          if(list.length>0) {
            window.location.href = val
          } else {
            return this.$toast({
              type: 'fail',
              message: '请先绑定房产！',
              duration: 1000,
              onClose: () => {
                this.$router.push({
                  path: '/houseRegister',
                })
              }
            })
          }
        }
      })
    },
    goPage_veterans(val) {
      
      if (this.$store.getters.userInfo.militaryService !== '3') {
        this.$toast.fail('您不是退役军人！')
        return
      }
      this.$router.push({
        path: val,
      })
    },
  },
}
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
.con {
  background-color: #fff;
  // padding-bottom: 50px;
}
.arrow {
  width: 10px;
  height: 10px;
  border: 2px solid #313131;
  margin-left: 5px;
  margin-top: -4px;
  border-top: transparent;
  border-left: transparent;
  
  transform: rotate(45deg);
}
.banner {
  width: 350px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
}
#launch-btn {
  position: relative;
  top: 0;
  z-index: 9999;
  display: block;
  width: 100%;
  height: 100%;
}

.swipe-item {
  color: #fff;
  font-size: 20px;
  // line-height: 150px;
  // height: 149px;
  text-align: center;
  background-color: #39a9ed;
  height: 140px;
  img {
    width: 100%;
    // height: auto;
    height: 140px;
  }
}
.userBtn {
  width: 100%;
  padding: 10px 0;
}
.links-list {
  margin: 20px 10px;
  .links-title {
    color: #434343;
    font-size: 16px;
    padding-left: 6px;
  }
  .links {
    margin: 12px 0;
    text-align: center;
    .link-item {
      
      margin-bottom: 10px;
      
      .div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: #fff;
        border-radius: 8px;
        transition: transform 0.3s;
        line-height: 24px;
        img {
          width: 48px;
          height: 48px;
          margin-bottom: 10px;
        }
        i {
          width: 36px;
          height: 36px;
          font-size: 30px;
          margin-bottom: 10px;
        }
        span {
          color: #2e2e2e;
          font-size: 14px;
        }
      }
      .div-2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        border-radius: 10px;
        margin: 0 5px;
        padding: 0 24px;
        box-sizing: border-box;
        font-size: 14px;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
.more-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #434343;
  font-weight: 700;
  padding-left: 6px;
  margin-bottom: 16px;
  .more {
    font-size: 12px;
    color: #999999;
    font-weight: normal;
    display: flex;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
      margin-left: 12px;
    }
  }
}
.articles {
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    box-sizing: border-box;
    padding: 0 8px;
  }
  .van-image {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .right {
    height: 60px;
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 12px;
      font-weight: 700;
    }
    .desc {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
.estateBox {
  // width: 100%;
  width: fit-content;
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  padding: 0 12px;
  color: #313131;
  .name {
    margin-left: 3px;
    font-size: 16px;
  }
  .down {
    transform: rotate(90deg);
  }
}
</style>
